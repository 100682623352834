<template>
  <q-header elevated>
    <q-toolbar>
      <q-toolbar-title class="q-my-xs">
        <q-img
          :src="settings.logo_url ?? logo_url"
          style="max-width: 150px"
        ></q-img>
      </q-toolbar-title>
    </q-toolbar>
    <CurrentStatus class="fixed-top-right" />
  </q-header>
  <div
    class="row justify-between q-pa-md"
    v-show="writeOffSettings.length == 0"
  >
    <div class="col-4">
      <q-btn
        style="width: 50px"
        class="float-left"
        color="primary"
        label="Terug"
        no-caps
        @click="terug()"
      />
    </div>
    <!-- <div class="col-4">
      <q-btn
        style="width: 75px"
        class="float-right"
        color="primary"
        label="Afboeken"
        no-caps
        @click="afboeken()"
        v-if="afboekenVisible"
      />
    </div> -->
  </div>
  <div class="row q-pa-md" v-show="writeOffSettings.length > 0">
    <div v-for="(writeOffSetting, key) in writeOffSettings" :key="key">
      <div
        class="q-px-sm"
        v-if="
          writeOffSetting.enabled &&
          checkWriteOffDependencies(writeOffSetting.dependencies)
        "
      >
        <q-btn
          class="float-left"
          color="primary"
          :label="writeOffSetting.name"
          no-caps
          @click="writeOff(writeOffSetting)"
        />
      </div>
    </div>
  </div>
  <div class="q-pa-md row q-gutter-md">
    <q-stepper
      flat
      animated
      v-model="step"
      header-nav
      vertical
      ref="stepper"
      color="primary"
      class="q-pa-none"
      :class="colSize"
    >
      <q-card
        v-for="(value, key, index) in components_map.stepper"
        :key="key"
        :class="index + 1 >= 2 ? 'q-mt-sm' : ''"
      >
        <q-step
          :name="index + 1"
          :title="value.title"
          :icon="value.icon"
          :done="step > index + 1"
          :header-nav="step > index + 1 && !readonly"
        >
          <component
            :is="key"
            :component_settings="components_map.child_component_settings[key]"
            :settings="components_map.settings[key]"
          />
          <!-- <Address style="margin-left: -26px" /> -->
        </q-step>
      </q-card>
    </q-stepper>
    <overview class="col" />
  </div>
</template>

<script async>
import { useQuasar } from "quasar";
import { defineComponent, onMounted } from "vue";
import { computed } from "@vue/reactivity";
import store from "../store";

import { useStore } from "vuex";

const components_map = store.getters.component_settings;

export default defineComponent({
  setup() {
    return {
      component_stepper: components_map.stepper,
    };
  },
  components: components_map.components,
});
</script>

<script setup>
import {
  cc_Enabled,
  cc_IsEnabled,
  cc_GotoPage,
} from "../plugins/cc-integration.js";
import { defineAsyncComponent } from "vue";

const $q = useQuasar();
const $store = useStore();

const CurrentStatus = defineAsyncComponent(() =>
  import("@/components/Header/CurrentStatus.vue")
);

onMounted(() => {
  setTimeout(() => {
    cc_IsEnabled();
  }, 3000);
});

const settings = computed(() => $store.getters.projectSettings);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const colSize = computed(() => ($q.screen.lt.md ? "col-12 q-pr-md" : "col-10"));
const step = computed({
  get() {
    if (typeof $store.getters.record?.current_step != "number") {
      return parseInt($store.getters.record?.current_step);
    }
    return $store.getters.record?.current_step || 1;
  },
  set(newVal) {
    $store.dispatch("setCurrentStep", newVal);
  },
});

const logo_url = computed(() => {
  return $store.getters.record?.Certified_Partner
    ? "https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/logo-certified.png"
    : "https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_improvers_white.png";
});

const recordStatus = computed(() => {
  return $store.getters.record?.Status_ID ?? 1;
});

const email_sent = computed(() => {
  return $store.getters.record?.email_sent != null ? true : false;
});

const writeOffSettings = computed(() => {
  return $store.getters.writeOffSettings ?? [];
});

function terug() {
  console.log(cc_Enabled.value);
  // if (!cc_Enabled.value) {
  //   location.href = process.env.VUE_APP_BASE_ENCODE_URL;
  //   return;
  // }
  cc_GotoPage("LaadRecord");
}

function writeOff(writeOff) {
  if (writeOff.type == "URL") {
    location.href = writeOff.target;
  } else if (writeOff.type == "TK") {
    cc_GotoPage(writeOff.target);
  }
}

function checkWriteOffDependencies(dependencies) {
  let result = true;
  for (const [key, dependency] of Object.entries(dependencies)) {
    switch (dependency.type) {
      case "status":
        if (!dependency.value.includes(recordStatus.value)) {
          result = false;
        }
        break;
      case "active_component":
        if (!dependency.value.includes(step.value)) {
          result = false;
        }
        break;
      case "email_has_been_sent":
        if (!email_sent.value) {
          result = false;
        }
        break;
    }
  }
  return result;
}
</script>
